export default function single_event() {
	
  $('.event-container').imagesLoaded(function(){
    initEventDatesHeight();
  });

  $('.event-info__tabs li a').click(function(e){
    e.preventDefault();

    $('.event-info__content .tab-content').hide();
    $($(this).attr('href')).show();

    initEventDatesHeight();
  });

  function initEventDatesHeight() {
    if ($(window).width() >= 768) {
      $('.event-dates__list').height($('.event-info').outerHeight() - $('.event-dates__title').outerHeight() - 30);
    }
  }
  
}