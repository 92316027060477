export default function nav() {	
	$('#header .hamburger').on('click', function (e) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '.nav-container' ).toggleClass( 'active' );
		$( 'body' ).toggleClass( 'mobile-menu-active' );
  });

  $('.btn-mobile-search').click(function(e){
    e.preventDefault();

    $('#mobile-search').slideToggle();
  });
  
  $('#menu-main-menu .link-section > a').on('click', function(e) {
    e.preventDefault();
    
		if (this.hash !== "") {
      // Store hash
      var hash = this.hash;
      var pos = $(hash).offset().top - $('#header').outerHeight();

      $('html, body').animate({
        scrollTop: pos
      }, 800, function(){
        $( '#header .hamburger' ).removeClass( 'is-active' );
		    $( '.nav-container' ).removeClass( 'active' );
      });
    }
  });
}