import 'imagesloaded';

import nav from './modules/nav';
import page from './modules/page';
import filters from './modules/filters';
import single_event from './modules/single-event';

$(document).ready(function($) {

  nav();
  page();
  filters();
  single_event();

});