export default function filters() {
	
  $('.event-filters .filter-month a').click(function(e){
    e.preventDefault();
    
    $('.event-filters .filter-month li').removeClass('active');
		$(this).parent().addClass('active');

    filter_events();
  });

  $('.event-filters .filter-category a').click(function(e){
    e.preventDefault();
    
    $('.event-filters .filter-category li').removeClass('active');
		$(this).parent().addClass('active');

    filter_events();
  });

  function filter_events() {
    var filter_month = $('.event-filters .filter-month li.active a').data('month').toString();
    var filter_category = $('.event-filters .filter-category li.active a').data('category').toString();

    $('.event-list .item').each(function(){
      var months = $(this).data('month').toString().split('-');
      var categories = $(this).data('category').toString().split('-');
      console.log(filter_month);
      console.log(months);
      console.log(months.includes(filter_month));

      if (filter_month > 0 && !months.includes(filter_month)) {
        $(this).hide();
        return;
      }

      if (filter_category > 0 && !categories.includes(filter_category)) {
        $(this).hide();
        return;
      }

      $(this).show();
    });
  }
	
}